import React, { useEffect, useState } from 'react';

const Counter = ({ endNumber, duration = 2000 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const increment = endNumber / (duration / 100);
    let currentCount = 0;
    const interval = setInterval(() => {
      currentCount += increment;
      if (currentCount >= endNumber) {
        clearInterval(interval);
        setCount(endNumber); 
      } else {
        setCount(Math.floor(currentCount));
      }
    }, 100); 

    return () => clearInterval(interval);
  }, [endNumber, duration]);

  const digits = count.toString().split('');

  return (
    <div className='flex flex-col sm:flex-row items-center space-x-2 my-5'>
      <div className='flex flex-row items-center space-x-2 my-5'>
        {digits.map((digit, index) => (
          <div
            key={index}
            className= ' bg-button1 rounded-lg h-10 w-10 flex items-center justify-center text-white text-md font-semibold'
          >
            {digit}
          </div>
        ))}
      </div>
      <div className='text-gray-400 text-[16px]'>
        хүмүүс Greencard-аар бүргэлээ хийлгэжээ.
      </div>
    </div>
  );
};

export default Counter;

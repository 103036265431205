import axios from 'axios';

// const BASE_URL = 'http://localhost/usa';
const BASE_URL = 'https://greencardusa.mn/';

const api = axios.create({
    baseURL: `${BASE_URL}`,
});

export const registerUser = async (userData) => {
    try {
        const data = {
            ...userData,
            action: 'register',
        };

        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'An error occurred' };
    }
};

export const loginUser = async (email, password) => {
    try {
        const data = {
            email,
            password,
            action: 'login',
        };

        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        console.error('Login error:', error);
        return { error: 'Login failed.' };
    }
};

export const requestPasswordReset = async (email) => {
    try {
        const data = {
            email,
            action: 'forgot_password',
        };

        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'An error occurred. Please try again.' };
    }
};

export const resetPassword = async (token, newPassword) => {
    try {
        const data = {
            token,
            new_password: newPassword,
            action: 'reset_password',
        };

        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'An error occurred. Please try again.' };
    }
};

export const setForm = async (formData, email) => {
    try {
        const data = {
            ...formData,
            action: 'set_form',
            email: email
        };

        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'An error occurred' };
    }
};

export const getUserInfo = async (email) => {
    try {
        const response = await api.get('/server.php', {
            params: {
                email,
                action: 'get_user_info',
            },
        });
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'An error occurred while fetching user information.' };
    }
};

export const logoutUser = () => {
    console.log('User logged out.');
};

import React, { useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { FaCamera } from 'react-icons/fa';
import { GoAlert } from "react-icons/go";
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import CustomDialog from '../ui/dialog';
import Header from './header';
import male from "../../assets/male.svg";
import female from "../../assets/female.svg";
import Datepicker from "react-tailwindcss-datepicker";
import { UserContext } from "../../userContext";

function SecondForm(props) {
    const [isDialogOpen, setDialogOpen] = useState(true);
    const { form, setForm } = useContext(UserContext); 
    const [ status ] = useState(form?.["family-status"]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if(form?.["personal-info"]){
            setFormData(form?.["personal-info"]);
        }
    }, [form]);

    const MAX = new Date();
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/family-status", { state: { status } }); 
    };

    const handleNextPage = async () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setForm(prevForm => {
            const updatedForm = { ...prevForm, "personal-info": formData };
            return updatedForm;
        });

        if (status === "married") {
            navigate("/partner-info");
        } else if (status === "widowed" || status === "divorced" || status === "legally-separated" || status === "single") {
            navigate("/child-info");
        }
    }

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.firstName) validationErrors.firstName = "Овог заавал оруулна уу.";
        if (!formData.lastName) validationErrors.lastName = "Нэр заавал оруулна уу.";
        if (!formData.gender) validationErrors.gender = "Хүйсээ сонгоно уу.";
        if (!formData.birthdate) validationErrors.birthdate = "Төрсөн огноо заавал оруулна уу.";
        if (!formData.phone) validationErrors.phone = "Утасны дугаар заавал оруулна уу.";
        if (!formData.email) validationErrors.email = "И-мэйл хаяг заавал оруулна уу.";
        if (!formData.city) validationErrors.city = "Хот заавал оруулна уу.";

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            validationErrors.email = "И-мэйл хаяг зөв биш байна.";
        }

        const phoneRegex = /^[0-9]*$/;
        if (formData.phone && !phoneRegex.test(formData.phone)) {
            validationErrors.phone = "Утасны дугаар зөвхөн тоогоор байх ёстой.";
        }

        if (!formData.uploadedImage) {
            validationErrors.file = 'Зураг оруулна уу.';
        }

        return validationErrors;
    };

    const genders = [
        {
            id: 'male',
            label: 'Эрэгтэй',
            icon: <img src={male} alt='' className='w-[20px] h-[20px]' />,
        },
        {
            id: 'female',
            label: 'Эмэгтэй',
            icon: <img src={female} alt='' className='w-[20px] h-[20px]' />,
        },
    ];

    const handleGenderChange = (id) => {
        setFormData(prevData => ({
            ...prevData,
            gender: id
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            gender : null
        }));
    };

    const handleFileChange = (e) => {
        setDialogOpen(true);
        const file = e.target.files[0];
    
        if (file) {
            const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const MAX_SIZE = 240 * 1024;
    
            if (!validImageTypes.includes(file.type)) {
                setErrors((prev) => ({ ...prev, file: 'Зураг зөвхөн JPG, PNG, GIF форматаар байх ёстой.' }));
                return;
            }
    
            if (file.size > MAX_SIZE) {
                const reader = new FileReader();
    
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
    
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
    
                        const MAX_WIDTH = 600; 
                        const MAX_HEIGHT = 600;
                        let width = img.width;
                        let height = img.height;
    
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
    
                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, width, height);
    
                        canvas.toBlob((blob) => {
                            if (blob) {
                                const base64Reader = new FileReader();
                                base64Reader.onloadend = () => {
                                    const base64data = base64Reader.result;
                                    const resizedImageUrl = URL.createObjectURL(blob);
                                    setFormData(prevData => ({
                                        ...prevData,
                                        uploadedImage: resizedImageUrl,
                                        base64: base64data
                                    }));
                                };
                                base64Reader.readAsDataURL(blob);
                            }
                        }, 'image/jpeg', 0.8);
                    };
                };
                reader.readAsDataURL(file);
                setErrors((prev) => ({ ...prev, file: '' }));
            } else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64data = reader.result;
                    const imageUrl = URL.createObjectURL(file);
                    setFormData(prevData => ({
                        ...prevData,
                        uploadedImage: imageUrl,
                        base64: base64data
                    }));
                };
                reader.readAsDataURL(file);
                setErrors((prev) => ({ ...prev, file: '' }));
            }
        }
    };
    
    const handleDateChange = (date) => {
        setFormData(prevData => ({
            ...prevData,
            birthdate : date
        }));
        
        setErrors(prevErrors => ({
            ...prevErrors,
            birthdate: null
        }));
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };

    return (
        <div className='flex flex-col items-center w-full p-4'>
            <div className='flex flex-col items-center w-full sm:w-[70%]'>
                <Header status={status} pageNumber={1} />
                <div className='w-full p-[40px] flex flex-col items-start border-[1px] border-gray-600 my-8 rounded-xl'>
                    <div className='text-white text-[20px] font-bold text-center my-2 w-full'>
                        Өөрийн мэдээлэл
                    </div>
                    <div className='text-gray-400 text-[14px] text-center w-full border-b-[1px] border-gray-500 pb-9 mb-5'>
                        Хувийн мэдээлэлээ үнэн зөвөөр оруулаарай.
                    </div>
                    <div className='flex flex-col md:flex-row justify-between w-full my-2'>
                        <div className="space-y-2 w-full md:w-[49%] bg-transparent">
                            <Label htmlFor="firstName">Овог</Label>
                            <Input
                                id="firstName"
                                name="firstName"
                                type="text"
                                placeholder="Овог"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.firstName && <p className="text-red-500">{errors.firstName}</p>}
                        </div>
                        <div className="space-y-2 w-full md:w-[49%]">
                            <Label htmlFor="lastName">Нэр</Label>
                            <Input
                                id="lastName"
                                name="lastName"
                                type="text"
                                placeholder="Нэр"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.lastName && <p className="text-red-500">{errors.lastName}</p>}
                        </div>
                    </div>
                    <Label htmlFor="gender">Хүйс</Label>
                    <div className='flex flex-col md:flex-row justify-between w-full items-center'>
                        {genders.map((e) => (
                            <button key={e.id}
                                onClick={() => handleGenderChange(e.id)}
                                className={`flex py-[10px] w-full md:w-[49%] items-center rounded-md mt-4 mb-2 transition-all ${formData.gender === e.id ? 'border-2 border-pri' : 'border-[1px] border-gray-600'}`}>
                                <div className={`mx-4 p-1 rounded-full border-2 ${formData.gender === e.id ? 'border-pri' : 'border-gray-600'}`}>
                                    <div className={`h-[10px] w-[10px] rounded-full ${formData.gender === e.id ? 'bg-pri' : 'bg-transparent'}`}></div>
                                </div>
                                <div className='flex items-center text-white text-[14px] text-start w-[90%] font-normal'>
                                    <span className='mr-5'>{e.label}</span>
                                    {e.icon}
                                </div>
                            </button>
                        ))}
                    </div>
                    {errors.gender && <p className="text-red-500">{errors.gender}</p>}

                    <div className="flex flex-col space-y-2 w-full my-2">
                        <Label htmlFor="birthdate">Төрсөн огноо</Label>
                        <Datepicker
                            maxDate={MAX}
                            primaryColor={"green"}
                            useRange={false}
                            asSingle={true}
                            value={formData.birthdate}
                            onChange={(date) => handleDateChange(date)}
                            inputClassName={`border border-gray-500 rounded-md p-2 w-full bg-transparent text-white`}
                        />
                        {errors.birthdate && <p className="text-red-500">{errors.birthdate}</p>}
                    </div>
                    <div className='flex flex-col md:flex-row justify-between w-full'>
                        <div className="space-y-2 w-full md:w-[49%] bg-transparent">
                            <Label htmlFor="phone">Утасны дугаар</Label>
                            <Input
                                id="phone"
                                name="phone"
                                type="tel"
                                placeholder="Утасны дугаар"
                                required
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="space-y-2 w-full md:w-[49%]">
                            <Label htmlFor="email">И-мэйл хаяг</Label>
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                placeholder="И-мэйл хаяг"
                                required
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {errors.email && <p className="text-red-500">{errors.email}</p>}
                        </div>
                    </div>
                    <div className="space-y-2 w-full mt-4">
                        <Label htmlFor="city">Хот</Label>
                        <Input
                            id="city"
                            name="city"
                            type="text"
                            placeholder="Хот"
                            required
                            value={formData.city}
                            onChange={handleInputChange}
                        />
                        {errors.city && <p className="text-red-500">{errors.city}</p>}
                    </div>
                    <p className='text-gray-400 text-[14px] text-start w-full mb-6 mt-2'>
                        Үүнд зөвхөн төрсөн хотоо бичнэ. Улс, дүүрэг, хороо, аймаг ХАМААРАХГҮЙ буюу БИЧИХГҮЙ болно.
                    </p>

                    <Label htmlFor="upload-photo" className="mb-1">Зураг оруулах</Label>
                    <div className='flex flex-col md:flex-row justify-between my-3'>
                        <button
                            className='flex flex-col items-center w-full md:w-[25%] p-5 border-dashed border-2 border-gray-500 rounded-md font-normal text-[14px] focus:outline-none'
                            onClick={() => document.getElementById('upload-photo').click()}
                        >
                            {formData.uploadedImage ? (
                                <div className='w-full flex justify-center'>
                                    <img
                                        src={formData.uploadedImage}
                                        alt="Uploaded Preview"
                                        className='w-[200px] h-[200px] object-fit rounded-md'
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className='bg-button1 p-3 rounded-full mb-3'>
                                        <FaCamera className='h-[24px] w-[24px] fill-pri' />
                                    </div>
                                    <div className='text-gray-400'> Зураг оруулах бол </div>
                                    <div className='text-pri font-normal'> Энд дарна уу. </div>
                                </>
                            )}
                        </button>
                        <input
                            type="file"
                            accept="image/*"
                            id="upload-photo"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <div className='flex flex-col p-5 items-start w-full md:w-[73%] border border-warning rounded-md font-normal text-[14px] text-white mt-3 md:mt-0'>
                            <div className='flex items-center mb-1'>
                                <GoAlert className='h-[16px] w-[16px] fill-orange-400' />
                                <span className='ml-3 font-semibold'> Зураг сонгохдоо анхаарах зүйлс </span>
                            </div>
                            <span> Та одоогийн гэр бүлийн нөхцөл байдлаа сонгоод бүртгэлээ үргэлжлүүлээрэй
                                ингэснээр та сугалаанд хожсон тохиолдолд бичиг баримт бүрдүүлэхэд илүү хялбар байх болно. </span>
                        </div>
                    </div>  
                    {errors.file && <p className="text-red-500">{errors.file}</p>}
                </div>
                <CustomDialog 
                    isOpen={isDialogOpen} 
                    onOpenChange={setDialogOpen}
                    uploadedImage={formData.uploadedImage}
                    onClose={() => setDialogOpen(false)}
                />
            </div>
            <div className='bg-gray-500 w-full h-[1px]' />
            <div className='flex flex-row w-[90%] md:w-[70%] my-4 items-center justify-end'>
                <button className="cursor-pointer text-gray-400 hover:text-white mx-5" onClick={handleBack}>
                    Буцах
                </button>
                <button className='text-pri bg-button1 px-6 py-3 rounded-lg font-semibold mt-2 hover:bg-gradient-green hover:text-white transition-all duration-300'
                    onClick={handleNextPage}
                >
                    Үргэлжлүүлэх
                </button>
            </div>
        </div>
    );
}

export default SecondForm;

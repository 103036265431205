import React, { useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { GoAlert } from 'react-icons/go';

function CustomDialog({ isOpen, onOpenChange, uploadedImage, onClose }) {
    const [processedImage, setProcessedImage] = useState(null);

    useEffect(() => {
        const loadImage = () => {
            if (uploadedImage) {
                const img = new Image();
                img.src = uploadedImage;
                img.onload = () => {
                    const size = 600;
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = size;
                    canvas.height = size;

                    ctx.drawImage(img, 0, 0, size, size);

                    const headTop = size * 0.10; 
                    const headBottom = size * 0.72;
                    const eyeTop = size * 0.29;
                    const eyeBottom = size * 0.46;

                    ctx.fillStyle = 'rgba(0, 255, 0, 0.3)'; 
                    ctx.fillRect(0, headTop, size, headBottom - headTop);

                    ctx.fillStyle = 'rgba(255, 0, 0, 0.3)';
                    ctx.fillRect(0, eyeTop, size, eyeBottom - eyeTop);

                    ctx.strokeStyle = 'black';
                    ctx.lineWidth = 2;
                    ctx.setLineDash([5, 5]);
                    ctx.beginPath();
                    ctx.moveTo(size / 2, 0);
                    ctx.lineTo(size / 2, size);
                    ctx.stroke();

                    setProcessedImage(canvas.toDataURL());
                };
            }
        };

        loadImage();
    }, [uploadedImage]);

    return (
        <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70" />
            <Dialog.Content className="fixed p-4 sm:p-6 w-[95%] max-w-[90%] sm:max-w-[60%] bg-white rounded-lg shadow-lg top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] border border-gray-800">
                <Dialog.Title className="text-lg sm:text-2xl font-bold flex items-center mb-3 text-black">
                    <GoAlert className="text-orange-500 mr-2" />
                    {!processedImage ? 'Зураг оруулах заавар, зөвлөгөө' : 'Зургийг урьдчилан харах'}
                </Dialog.Title>
                <Dialog.Description className="text-gray-800 mb-4 leading-relaxed flex flex-col sm:flex-row items-center justify-around ">
                    {processedImage 
                        ? (
                            <div className="flex flex-col items-center">
                                <div className="relative w-[280px] h-[280px] sm:w-[400px] sm:h-[400px] overflow-hidden rounded-md border border-gray-300">
                                    <img 
                                        src={processedImage} 
                                        alt="Uploaded Preview" 
                                        className="w-full h-full object-cover" 
                                    />
                                </div>
                            </div>
                        ) 
                        : (
                            <div className="text-sm sm:text-base">
                                <p>Green Card lottery өргөдөлд оруулах зурагны заавар:</p>
                                <ul className="list-disc pl-5 mt-2 text-gray-700">
                                    <li>Зураг нь тод, арьсны өнгө нь нарийвчлалтай, сүүдэргүй байх ёстой.</li>
                                    <li>Толгой нь төвлөрсөн, хэмжээг нь зөв, тэгш байх шаардлагатай.</li>
                                    <li>Зураг нь хурц, пиксел болон принтерийн цэгүүдгүй, тодорхой байдалтай байх ёстой.</li>
                                    <li>Зураг нь өөрчлөгдөөгүй, анхны, бодит зургийг агуулсан байх ёстой.</li>
                                    <li>Нейтрал илэрхийлэлтэй, камер руу шууд харж, нээлттэй, найрсаг байх шаардлагатай.</li>
                                    <li>Нүд нь тод, бүх нүүр илэрхий харагдаж, сүүдэр эсвэл хувцас нүүрийг хаах ёсгүй.</li>
                                    <li>Тагтаар эсвэл толгойн таглаатай зүүхийг хориглодог, зөвхөн шашны болон эмнэлгийн зориулалтаар зөвшөөрнө.</li>
                                    <li>Нүдний шил зүүхийг хатуу хориглоно.</li>
                                    <li>Фон нь цагаан эсвэл бараан цагаан, сүүдэргүй, текстур, объект, шугамгүй, цэвэр байх шаардлагатай.</li>
                                </ul>
                            </div>
                        )
                    }
                    {processedImage && <div className='flex flex-col items-start mt-4'>
                        <div className="text-green-500 text-sm font-semibold bg-white px-2 py-1 rounded shadow-md">
                            Толгойн хэсэг
                        </div>
                        <div className="text-red-500 text-sm font-semibold bg-white px-2 py-1 rounded shadow-md mt-2">
                            Нүдний хэсэг
                        </div>
                        <div className="text-black text-sm font-semibold bg-white px-2 py-1 rounded shadow-md mt-2">
                            Толгойн дунд шугам
                        </div>
                    </div>}
                </Dialog.Description>
                <div className="flex justify-end mt-6">
                    <Dialog.Close asChild>
                        <button
                            className="px-4 py-2 bg-pri text-white rounded-md hover:bg-opacity-80 focus:outline-none"
                            onClick={onClose}
                            aria-label="Close dialog"
                        >
                            {!processedImage ? 'Хаах' : 'Болсон'}
                        </button>
                    </Dialog.Close>
                </div>
            </Dialog.Content>
        </Dialog.Root>
    );
}

export default CustomDialog;

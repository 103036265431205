import React, { useState, useEffect, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { UserContext } from "../../userContext";
import 'react-toastify/dist/ReactToastify.css';

function FirstForm() {
    const [selectedStatus, setSelectedStatus] = useState(null);
    const navigate = useNavigate();
    const {form, setForm } = useContext(UserContext); 

    useEffect(() => {
        setSelectedStatus(form?.["family-status"]);
    }, [form]);

    const statuses = [
        {
            id: 'married',
            label: 'Гэр бүлтэй',
            description: 'Албан ёсоор гэрлэлтээ батлуулсан болон шүүхийн шийдвэрээр буюу хууль ёсоор гэрлэлтээ цуцлуулаагүй тохиолдолд заавал тухайн гэр бүлийн хүний мэдээллийг оруулах ёстой болно.',
        },
        {
            id: 'single',
            label: 'Ганц бие',
            description: 'Энэ ангилалд ганц бие ба гэрлэлтээ батлуулаагүй хамтран амьдарч байгаа тохиолдолд',
        },
        {
            id: 'widowed',
            label: 'Бэлэвсэн',
            description: 'Албан ёсоор гэрлэлтээ батлуулсан хамтран амьдрагч нь нас барсан тохиолдолд',
        },
        {
            id: 'divorced',
            label: 'Салсан',
            description: 'Энэ ангилалд албан ёсоор шүүхийн шийдвэрээр буюу хууль ёсоор гэрлэлтээ цуцлуулаагүй.',
        },
        {
            id: 'legally-separated',
            label: 'Хууль ёсны дагуу салсан',
            description: 'Шүүхээр шийдвэрийн дагуу хууль ёсоор гэрлэлтээ цуцлуулаагүй болон цуцлуулах өргөдлөө хүлээлгэж өгсөн.',
        },
    ];

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        if (!selectedStatus) {
            toast.warning("Та гэр бүлийн байдал сонгоогүй байна.");
            return;
        }

        setForm(prevForm => {
        const updatedForm = { ...prevForm, "family-status": selectedStatus };

        navigate("/personal-info");

        return updatedForm;
    });
    };

    const handleStatusChange = (id) => {
        setSelectedStatus(id === selectedStatus ? null : id);
    };

    return (
        <div className='flex flex-col items-center px-4 sm:px-8'>
            <ToastContainer />
            <div className='flex flex-col items-center w-full sm:w-[70%] my-8'>
                <div className='text-white text-[28px] sm:text-[34px] font-bold text-center my-2'>
                    Америкийн ногоон картны сугалаа 2026
                </div>
                <div className='text-gray-400 text-[14px] sm:text-[16px] text-center'>
                    Ногоон картны сугалаа 2026 нь 2024 оны 10 сарын 02 оос эхлэн 11 сарын 05 
                    хүртэл явагдах ба манай сайт нь америкийн ногоон картны сугалааны бүртгэлийн 
                    дагуу форумыг дагахаас гадна сугалааны эхний шатны шаардлага болох зургийн 
                    стандартийг хангуулан ажиллаж байна. Бүртгэл нь хийгдэж дууссаны дараа танд 
                    <b className='text-white'> CONFIRMATION CODE</b> илгээх болно. 
                </div>
                <div className='w-full p-4 sm:p-[40px] flex flex-col items-center border-[1px] border-gray-600 my-8 rounded-xl'>
                    <div className='text-white text-[18px] sm:text-[20px] font-bold text-center my-2'>
                        Гэр бүлийн байдал
                    </div>
                    <div className='text-gray-400 text-[12px] sm:text-[14px] text-center w-full border-b-[1px] border-gray-500 pb-4'>
                        Та одоогийн гэр бүлийн нөхцөл байдлаа сонгоод бүртгэлээ үргэлжлүүлээрэй ингэснээр <br className='hidden sm:block' />
                        та сугалаанд хожсон тохиолдолд бичиг баримт бүрдүүлэхэд илүү хялбар байх болно.
                    </div>

                    {statuses.map((status) => (
                        <button key={status.id} 
                            onClick={() => handleStatusChange(status.id)} 
                            className={`flex flex-row justify-between p-4 sm:p-[24px] bg-formBack w-full rounded-xl items-start my-4 transition-all ${selectedStatus === status.id ? 'border-2 border-pri' : 'border-[1px] border-gray-600'}`}>
                            <div className={`mr-4 p-1 rounded-full border-2 ${selectedStatus === status.id ? 'border-pri' : ' border-gray-600'}`}>
                                <div className={`h-[15px] w-[15px] rounded-full ${selectedStatus === status.id ? 'bg-pri' : 'bg-transparent'}`}>
                                </div>
                            </div>

                            <div className='text-gray-400 text-[12px] sm:text-[14px] text-start w-[95%]'>
                                <b>{status.label}</b> <br />
                                {status.description}
                            </div>
                        </button>
                    ))}
                </div>
            </div>
            <div className='bg-gray-500 w-full h-[1px]'></div>
            <div className='flex flex-row w-full sm:w-[70%] my-4 items-center justify-end'>
                <button className="cursor-pointer text-gray-400 hover:text-white mx-2 sm:mx-5" onClick={handleBack}>
                    Буцах
                </button>
                <button className='text-pri bg-button1 px-4 sm:px-6 py-2 sm:py-3 rounded-lg font-semibold mt-2 hover:bg-gradient-green hover:text-white transition-all duration-300'
                onClick={handleNext}>
                    Үргэлжлүүлэх
                </button>
            </div>
        </div>
    );
}

export default FirstForm;

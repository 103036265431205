import React from 'react';

export default function Footer() {
    return (
        <div className='bg-main flex flex-col sm:flex-row items-center justify-between h-fit py-3 w-full sm:w-[70%] mx-auto border-t-[1px] border-gray-500 text-center sm:text-left'>
            <p className='text-gray-500 mb-2 sm:mb-0'>&copy; 2024 Greencard, inc. All rights reserved.</p>
            <div className='flex space-x-4 text-gray-500'>
                <a href='https://www.facebook.com/greencardusa2025/' target='_blank' rel='noopener noreferrer' className='hover:text-white'>
                    Facebook
                </a>
                <div>|</div>
                <a href='https://www.instagram.com' target='_blank' rel='noopener noreferrer' className='hover:text-white'>
                    Instagram
                </a>
            </div>
        </div>
    );
}

import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Landing from "./components/landing";
import Menu from "./components/menu";
import Footer from "./components/footer";
import Login from "./components/login/login";
import Register from "./components/login/register";
import ForgetPass from "./components/login/forgetpass";
import ResetPass from "./components/login/resetpass";
import Form1 from "./components/form/firstForm";
import Form2 from "./components/form/secondForm";
import Form3 from "./components/form/thirdForm";
import Form4 from "./components/form/fourthForm";
import QPay from "./components/form/qpay";
import { ToastContainer } from 'react-toastify';
import { UserProvider } from "./userContext";

function App() {
  const newsRef = useRef(null);
  const contactRef = useRef(null);
  const helpRef = useRef(null);
  const location = useLocation();

  const scrollToNews = () => {
    if (newsRef.current) {
      newsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToHelp = () => {
    if (helpRef.current) {
      helpRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const hideMenuAndFooter = ["-info", "family-status", "qpay"].some((suffix) =>
    location.pathname.endsWith(suffix)
  );

  return (
    <div className="bg-main min-h-screen w-screen flex flex-col">
      {!hideMenuAndFooter && <Menu scrollToNews={scrollToNews} scrollToContact={scrollToContact} scrollToHelp={scrollToHelp} />}
      <div className="flex-grow flex items-center justify-center">
        <Routes>
          <Route path="/" element={<Landing newsRef={newsRef} contactRef={contactRef} helpRef={helpRef} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgetPass />} />
          <Route path="/reset-pass" element={<ResetPass />} />
          <Route path="/family-status" element={<Form1 />} />
          <Route path="/personal-info" element={<Form2 />} />
          <Route path="/partner-info" element={<Form3 />} />
          <Route path="/child-info" element={<Form4 />} />
          <Route path="/qpay" element={<QPay />} />
        </Routes>
      </div>
      {!hideMenuAndFooter && <Footer />}
    </div>
  );
}

function AppWrapper() {
  return (
    <UserProvider>
      <Router>
        <App />
        <ToastContainer 
          position="top-right" 
          autoClose={3000} 
          hideProgressBar={false} 
          newestOnTop 
          closeOnClick 
          pauseOnHover 
        />
      </Router>
    </UserProvider>
  );
}

export default AppWrapper;

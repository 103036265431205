// src/components/Register.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '../ui/card';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { registerUser } from '../../services/service';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState('');

    const navigate = useNavigate();

    const checkPasswordStrength = (pwd) => {
        const lengthCriteria = pwd.length >= 8;
        const numberCriteria = /\d/.test(pwd);
        const lowercaseCriteria = /[a-z]/.test(pwd);
        const uppercaseCriteria = /[A-Z]/.test(pwd);
        const specialCharacterCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(pwd);
        
        const criteriaCount = [lengthCriteria, numberCriteria, lowercaseCriteria, uppercaseCriteria, specialCharacterCriteria].filter(Boolean).length;

        if (criteriaCount < 2) {
            setPasswordStrength('Weak');
        } else if (criteriaCount === 2 || criteriaCount === 3) {
            setPasswordStrength('Medium');
        } else {
            setPasswordStrength('Strong');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name === '' || email === '' || password === '' || confirmPassword === '') {
            toast.warning('Бүх талбарыг бөглөнө үү.');
            return;
        }
        if (password !== confirmPassword) {
            toast.error('Нууц үг таарахгүй байна.');
            return;
        }

        try {
            const result = await registerUser({ name, email, password });

            if (result.error) {
                toast.error(result.error);
            } else {
                setName('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                setPasswordStrength('');
                navigate('/login'); 
                toast.success(result.message || 'Бүртгэл амжилттай боллоо!');
            }
        } catch (error) {
            toast.error('Серверийн алдаа гарлаа. Дахин оролдоно уу.');
        }
    };

    return (
        <Card className="w-full max-w-sm mx-auto my-1 sm:my-8">
            <CardHeader className="space-y-1">
                <CardTitle className="text-2xl font-bold">Бүртгүүлэх</CardTitle>
                <CardDescription>Сугалаанд оролцохын тулд бүртгүүлнэ үү.</CardDescription>
            </CardHeader>
            <CardContent>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="name">Нэр</Label>
                        <Input
                            id="name"
                            type="text"
                            placeholder="Нэр"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="email">И-мейл</Label>
                        <Input
                            id="email"
                            type="email"
                            placeholder="m@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="password">Нууц үг</Label>
                        <div className="relative">
                            <Input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    checkPasswordStrength(e.target.value);
                                }}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                            >
                                {!showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        {password && <p className={`text-sm ${passwordStrength === 'Weak' ? 'text-red-500' : passwordStrength === 'Medium' ? 'text-yellow-500' : 'text-green-500'}`}>{passwordStrength}</p>}
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="confirmPassword">Нууц үе давтах</Label>
                        <div className="relative">
                            <Input
                                id="confirmPassword"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                            >
                                {!showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>
                    <Button type="submit" className="w-full bg-green-reverse text-white hover:bg-gradient-green">
                        Бүртгүүлэх
                    </Button>
                </form>
                <div className="mt-4 text-center text-sm">
                    Аль хэдийн бүртгүүлсэн?{' '}
                    <Link to="/login" className="text-pri hover:underline mx-1">
                        Нэвтрэх
                    </Link>
                </div>
            </CardContent>
        </Card>
    );
}

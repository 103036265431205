import React from 'react';
import deactive2 from '../../assets/2.svg';
import deactive3 from '../../assets/3.svg';
import deactive4 from '../../assets/4.svg';
import dash from "../../assets/dashed.svg";
import active1 from '../../assets/1active.svg';
import active2 from '../../assets/2active.svg';
import active3 from '../../assets/3active.svg';
import correct from '../../assets/correct.svg';

function LoginHeader({ status, pageNumber }) {
    const renderStep = (stepNumber, isActive, label) => {
        let src;
        if (stepNumber < pageNumber) {
            src = correct;
        } else if (isActive) {
            src = stepNumber === 1 ? active1 :
                    stepNumber === 2 ? active2 :
                    stepNumber === 3 ? active3 :
                    deactive4;
        } else {
            src = stepNumber === 1 ? active1 :
                    stepNumber === 2 ? deactive2 :
                    stepNumber === 3 ? deactive3 :
                    deactive4;
        }

        return (
            <div className='flex items-center'>
                <img
                    src={src}
                    alt=''
                    className='w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] mx-2 sm:mx-3'
                />
                <span
                    className={`text-[12px] sm:text-[16px] font-semibold ${isActive ? 'text-white' : 'text-gray-400'}`}
                >
                    {label}
                </span>
            </div>
        );
    };

    const getLabel = () => {
        if (status === 'married') {
            return pageNumber === 1 ? 'Өөрийн мэдээлэл' :
                    pageNumber === 2 ? 'Эхнэр/Нөхрийн мэдээлэл' :
                    pageNumber === 3 ? 'Хүүхдийн мэдээлэл' :
                    'Төлбөр төлөлт';
        } else if (status === 'widowed' || status === 'divorced' || status === 'legally-separated' || status === 'single') {
            return pageNumber === 1 ? 'Өөрийн мэдээлэл' :
                    pageNumber === 2 ? 'Хүүхдийн мэдээлэл' :
                    'Төлбөр төлөлт';
        }
        return '';
    };

    return (
        <div className='sticky top-0 flex flex-col sm:flex-row justify-center sm:justify-evenly my-2 items-center w-full py-2 h-fit bg-main'>
            <div className='block sm:hidden'>
                {renderStep(pageNumber, true, getLabel())}
            </div>

            <div className='hidden sm:flex sm:items-center'>
                {renderStep(1, pageNumber === 1, 'Өөрийн мэдээлэл')}
                <img src={dash} alt='' className='hidden sm:block h-[2px] w-[24px]' />

                {status === 'married' && (
                    <>
                        {renderStep(2, pageNumber === 2, 'Эхнэр/Нөхрийн мэдээлэл')}
                        <img src={dash} alt='' className='hidden sm:block h-[2px] w-[24px]' />

                        {renderStep(3, pageNumber === 3, 'Хүүхдийн мэдээлэл')}
                        <img src={dash} alt='' className='hidden sm:block h-[2px] w-[24px]' />

                        {renderStep(4, pageNumber === 4, 'Төлбөр төлөлт')}
                    </>
                )}

                {(status === 'widowed' || status === 'divorced' || status === 'legally-separated' || status === 'single') && (
                    <>
                        {renderStep(2, pageNumber === 2, 'Хүүхдийн мэдээлэл')}
                        <img src={dash} alt='' className='hidden sm:block h-[2px] w-[24px]' />
                        {renderStep(3, pageNumber === 3, 'Төлбөр төлөлт')}
                    </>
                )}
            </div>
        </div>
    );
}

export default LoginHeader;
